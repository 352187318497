import { Redirect, Route, Switch } from 'react-router-dom';
import { retryLoad } from 'utils/loadableUtil';
import { useReactiveVar } from '@apollo/client';

import AdminTemplate from './components/admin/AdminTemplate';
import { isLoggedInVar } from './stores/adminUser';

const AdminUserPage = retryLoad(() => import('./pages/admin/UserPage'));
const AdminGoalPage = retryLoad(() => import('./pages/admin/GoalPage'));
const AdminTodoPage = retryLoad(() => import('./pages/admin/TodoPage'));
const AdminCoinLogPage = retryLoad(() => import('./pages/admin/CoinLogPage'));
const AdminDonatePage = retryLoad(() => import('./pages/admin/DonatePage'));
const AdminRankingPage = retryLoad(() => import('./pages/admin/RankingPage'));
const AdminWBNBoardPage = retryLoad(() => import('./pages/admin/WBNBoardPage'));
const AdminGroupPage = retryLoad(() => import('./pages/admin/GroupPage'));
const AdminGroupPostPage = retryLoad(
  () => import('./pages/admin/GroupPostPage'),
);
const AdminGroupPostImagePage = retryLoad(
  () => import('./pages/admin/GroupPostImagePage'),
);
const AdminPremiumLogPage = retryLoad(
  () => import('./pages/admin/PremiumLogPage'),
);
const AdminCouponPage = retryLoad(() => import('./pages/admin/CouponPage'));

const AdminConversionRatePage = retryLoad(
  () => import('./pages/admin/ConversionRatePage'),
);

const AdminRolePage = retryLoad(() => import('./pages/admin/RolePage'));
const AdminPermissionPage = retryLoad(
  () => import('./pages/admin/PermissionPage'),
);
const AdminBoardUserPage = retryLoad(() => import('pages/admin/BoardUserPage'));
const AdminMailPage = retryLoad(() => import('./pages/admin/AdminEmailPage'));
const AdminMailLogPage = retryLoad(() => import('./pages/admin/MailLogPage'));
const AdminMailTemplatePage = retryLoad(
  () => import('./pages/admin/MailTemplatePage'),
);

const NotFoundPage = retryLoad(() => import('./pages/NotFoundPage'));

const LoginPage = retryLoad(() => import('./components/login/LoginContainer'));
const RegisterPage = retryLoad(
  () => import('./components/register/RegisterContainer'),
);
const SearchPasswordPage = retryLoad(
  () => import('./pages/SearchPasswordPage'),
);

const CouponPage = retryLoad(() => import('./pages/CouponPage'));
const NoticePage = retryLoad(() => import('./pages/NoticePage'));
const BizPage = retryLoad(() => import('./pages/biz/BizPage'));

const AdminSubscriptionPage = retryLoad(
  () => import('./pages/admin/SubscriptionPage'),
);

const App = () => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  return (
    <Switch>
      {/* 사용자 라우터 */}
      <Route component={CouponPage} path="/coupon" />
      <Route component={NoticePage} path="/notice" />
      <Route component={SearchPasswordPage} path="/search-password" />

      {isLoggedIn && (
        <Route exact path="/" render={() => <Redirect to="/biz" />} />
      )}
      {isLoggedIn ? (
        <Route component={BizPage} path="/biz" />
      ) : (
        <Route exact path="/biz" render={() => <Redirect to="/login" />} />
      )}

      {isLoggedIn ? (
        <Route exact path="/login" render={() => <Redirect to="/biz" />} />
      ) : (
        <Route component={LoginPage} exact path="/login" />
      )}

      {/* 관리자 라우터 */}
      <Route
        path="/admin"
        render={() =>
          isLoggedIn ? (
            <>
              <AdminTemplate>
                <Switch>
                  <Route
                    component={() => <Redirect to="/admin/users/stats" />}
                    path="/admin"
                    exact
                  />

                  <Route component={AdminUserPage} path="/admin/users" />
                  <Route component={AdminTodoPage} path="/admin/todos" />
                  <Route component={AdminGoalPage} path="/admin/goals" />
                  <Route component={AdminCoinLogPage} path="/admin/coinLogs" />
                  <Route component={AdminDonatePage} path="/admin/donate" />
                  <Route component={AdminMailPage} path="/admin/mail" />
                  <Route component={AdminCouponPage} path="/admin/coupon" />
                  <Route
                    component={AdminPremiumLogPage}
                    path="/admin/premiumLog"
                  />
                  <Route
                    component={AdminConversionRatePage}
                    path="/admin/conversionRate"
                  />
                  <Route component={AdminRankingPage} path="/admin/ranking" />
                  <Route
                    component={AdminSubscriptionPage}
                    path="/admin/subscription"
                  />
                  <Route component={AdminRolePage} path="/admin/role" />
                  <Route
                    component={AdminPermissionPage}
                    path="/admin/permission"
                  />
                  <Route
                    component={AdminBoardUserPage}
                    path="/admin/board-user"
                  />
                  <Route component={AdminWBNBoardPage} path="/admin/wbn" />
                  <Route component={AdminGroupPage} path="/admin/group" />
                  <Route
                    component={AdminGroupPostPage}
                    path="/admin/groupPost"
                  />
                  <Route
                    component={AdminGroupPostImagePage}
                    path="/admin/groupPostImage"
                  />
                  <Route component={AdminMailLogPage} path="/admin/mailLogs" />
                  <Route
                    component={AdminMailTemplatePage}
                    path="/admin/mailTemplate"
                  />

                  <Route component={LoginPage} path="/stat" />

                  <Route component={NotFoundPage} />
                </Switch>
              </AdminTemplate>
            </>
          ) : (
            <Switch>
              <Route component={LoginPage} exact path="/admin/login" />
              <Route component={RegisterPage} exact path="/admin/register" />
              <Route component={() => <Redirect to="/admin/login" />} />
            </Switch>
          )
        }
      />

      {/* not found */}
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default App;
